import { Dispatch, SetStateAction } from 'react';

export function generateSupportAppLink(
  setShowModal: Dispatch<SetStateAction<boolean>>,
  mobileOS: string | undefined
) {
  !mobileOS
    ? setShowModal(true)
    : window.open(import.meta.env.VITE_SUPPORT_APP_DOWNLOAD, '_blank');
}
